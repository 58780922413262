import { FC, ReactNode } from 'react'
import { ReactComponent as IconLogo } from './london_luxury_logo.svg'
import './Logo.less'
type LogoProps = {
  color?: string
  width?: string
  height?: string
}

const Logo: FC<Partial<LogoProps>> = ({ color, width, height }) => (
  <IconLogo style={{ color, width, height }} className='logo-top' />
)

export { Logo }
