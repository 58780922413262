import React, { Suspense, FC } from 'react'
import { Row, Spin } from 'antd'
import { BrowserRouter as Router } from 'react-router-dom'
import { UI } from './UI'
import { Routing } from './Routing'
import CMS from './CMS'
import { ScrollToTop } from './ScrollToTop'

export const trademarkReduce = (classesArray: string[]) => {
  if(classesArray && classesArray.length) {
    classesArray.forEach(textClass => {
      const textList = document.querySelectorAll(`.${textClass}`) as NodeListOf<HTMLElement>
      textList.forEach(item => {
        if(item) {
          for (let i = 0; i < item.innerHTML.length; i++) {
            if (item.innerHTML[i] === '®') {
              item.innerHTML = item.innerHTML.replace('®', '<span class="trademark">®</span>')
              break
            }
          }
        }
      })

    })
  }
}

export const Loader: FC = () => (
  <Row justify={'center'} style={{ minHeight: '100vh' }} align={'middle'}>
    <Spin />
  </Row>
)

const App: FC = ({ children }) => {
  return (
    <CMS>
      <UI>
        <Router>
          <ScrollToTop />
          <Suspense fallback={<Loader />}>
            <Routing>{children}</Routing>
          </Suspense>
        </Router>
      </UI>
    </CMS>
  )
}

export { App }
