import { FC } from 'react'
import { Link } from 'react-router-dom'
import './ContactUs.less'
import { useMediaQuery } from 'react-responsive'

const ContactUs: FC = () => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isMobile = useMediaQuery({ maxWidth: 991 })

  return (
    <>
      {isDesktop && (
        <div className='contact-us'>
          <Link to={'/contacts'}>
            <p>CONTACT US</p>
          </Link>
        </div>
      )}
      {isMobile && (
        <div className='contact-us-mob'>
          <Link to={'/contacts'}>
            <p>Contact Us</p>
          </Link>
        </div>
      )}
    </>
  )
}

export { ContactUs }
