import { FC, createContext, useContext } from 'react'
import { GetContentComponent, GetContentQuery } from '../graphql'
import { Loader } from './index'
import fallback from '../graphql/index.json'
import { API } from './API'

const Content = createContext<GetContentQuery>(fallback.data)

const CMS: FC = ({ children }) => {
  return (
    <GetContentComponent>
      {({ data, loading }) => (loading ? <Loader /> : <Content.Provider value={data}>{children}</Content.Provider>)}
    </GetContentComponent>
  )
}

const useContent: () => GetContentQuery = () => useContext(Content)

function withBackend(Wrapped: FC): FC {
  return ({ children, ...props }) =>
    process.env.REACT_APP_API_URL ? (
      <API>
        <Wrapped>{children}</Wrapped>
      </API>
    ) : (
      <>{children}</>
    )
}

export default withBackend(CMS)
export { useContent }
